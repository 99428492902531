import React from 'react'
import { Flex, Box, Spinner } from '@chakra-ui/react';


export default function ComputerVision() {
  return (
    <div>
      Welcome to computer vision!
      
    </div>
  )
}
